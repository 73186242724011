import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomePage.vue'

Vue.use(VueRouter)

//修改Router的push方法和replace方法，避免跳转至相同路由时报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',//首页
    component: resolve => require(['../views/HomePage.vue'], resolve),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/product',
    name: 'product',//智能设计
    component: resolve => require(['../views/SecondaryPage/product.vue'], resolve),
    meta: {
      title: '产品-智能设计'
    }
  },
  {
    path: '/Bproduct',
    name: 'Bproduct',//选片选版
    component: resolve => require(['../views/SecondaryPage/Bproduct.vue'], resolve),
    meta: {
      title: '产品-选片选版'
    }
  },
  {
    path: '/Cproduct',
    name: 'Cproduct',//AI分片
    component: resolve => require(['../views/SecondaryPage/Cproduct.vue'], resolve),
    meta: {
      title: '产品-AI分片'
    }
  },
  {
    path: '/Dproduct',
    name: 'Dproduct',//毕业季设计
    component: resolve => require(['../views/SecondaryPage/Dproduct.vue'], resolve),
    meta: {
      title: '产品-毕业季设计'
    }
  },
  {
    path: '/Eproduct',
    name: 'Eproduct',//毕业季修图调色
    component: resolve => require(['../views/SecondaryPage/Eproduct.vue'], resolve),
    meta: {
      title: '产品-毕业季修图调色'
    }
  },
  {
    path: '/Fproduct',
    name: 'Fproduct',//辅助工具
    component: resolve => require(['../views/SecondaryPage/Fproduct.vue'], resolve),
    meta: {
      title: '产品-辅助工具'
    }
  },
  {
    path: '/Pricing',
    name: 'Pricing',//定价
    // component: resolve => require(['../views/fixAPrice/Pricing.vue'], resolve),
    component: resolve => require(['../views/fixAPrice/PricingV2.vue'], resolve),
    meta: {
      title: '定价'
    }
  },
  {
    path: '/SoftwareDownload',
    name: 'SoftwareDownload',//软件下载
    component: resolve => require(['../views/SoftwareDownload/index.vue'], resolve),
    meta: {
      title: '软件下载'
    }
  },
  {
    path: '/HelpCenter',
    name: 'HelpCenter',//视频教程
    component: resolve => require(['../views/HelpCenter/index.vue'], resolve),
    meta: {
      title: '视频教程'
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/testPage',
    name: 'testPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/testPage.vue')
  },
  {
    path: '/help',
    name: 'help',
    redirect: "/help/customer-service",
    component: () => import( '../views/Help/Help'),
    children: [
     {
      path: "/help/customer-service",
      name: "customer-service",
      component: () => import('../views/Help/customerService/CustomerService'),
     },
     {
      path: "/help/common",
      name: "help-common",
      component: () => import('../views/Help/helpCenter/HelpCenter.vue'),
     }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
